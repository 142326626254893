<template>
  <div class="content">
    <div class="col-6 mx-auto">
      <form class="form-group" @submit.prevent="onSubmit">
        <div
          class="row"
          v-for="(entry, index) in state.item"
          :key="`row_${index}`"
        >
          <label class="col-form-label col-2 flex-wrap">{{
            entry.label
          }}</label>
          <input
            class="form-control col-10"
            type="text"
            v-model="entry.value"
            :placeholder="
              entry.label == '코드'
                ? '코드 (한번 정해지면 후에 수정이 불가능합니다.)'
                : entry.label
            "
            :disabled="
              state.mode == 'ReadCodeItem' ||
              (state.mode == 'UpdateCodeItem' && entry.label == '코드')
            "
          />
        </div>
        <!-- 코드정보 조회페이지 -->
        <div
          class="btn-group-vertical col-12"
          v-if="state.mode == 'ReadCodeItem'"
        >
          <button class="btn btn-primary col-6 mx-auto" @click="clickModifyBtn">
            수정
          </button>
          <button class="btn btn-primary col-6 mx-auto" @click="clickRemoveBtn">
            삭제
          </button>
        </div>
        <!-- 코드정보 등록페이지- -->
        <div
          class="btn-group-vertical col-12"
          v-if="state.mode == 'CreateCodeItem'"
        >
          <button class="btn btn-primary col-6 mx-auto" @click="clickPostBtn">
            저장
          </button>
        </div>
        <!-- 코드정보 수정페이지- -->
        <div
          class="btn-group-vertical col-12"
          v-if="state.mode == 'UpdateCodeItem'"
        >
          <button class="btn btn-primary col-6 mx-auto" @click="clickSaveBtn">
            저장
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { onMounted, reactive } from "vue";
import codeService from "@/services/code.service";

// # hooks
const router = useRouter();
const routerParams = useRoute().params;

// # state
const state = reactive({
  mode: router.currentRoute.value.name || "",
  item: {
    code: {
      label: "코드",
      value: "",
    },
    codeName: {
      label: "코드명",
      value: "",
    },
    upperCode: {
      label: "상위코드",
      value: "",
    },
  },
  code: routerParams.code || "",
});

// # method
// 코드 단건 조회
const getItem = async () => {
  const data = (await codeService.findOne(state.code)).data;
  for (var key in state.item) {
    state.item[key].value = data[key];
  }
};

// # Btn Click Event
const clickPostBtn = async () => {
  if (!(await checkUpperCode())) {
    alert("입력한 상위코드가 존재하지 않습니다.");
    return;
  }

  if (state.item.code.value == "") {
    alert("코드입력은 필수입니다.");
    return;
  }

  const params = {};
  for (var key in state.item) {
    params[key] = state.item[key].value;
  }
  const res = await codeService.create(params);
  if (res.status == 200) {
    router.push({
      name: "ReadCodeList",
    });
  } else {
    alert(res.data.message);
    return;
  }
};

const clickModifyBtn = () => {
  router.push({
    name: "UpdateCodeItem",
    params: { code: state.code },
  });
  state.mode = "UpdateCodeItem";
};

const clickSaveBtn = async () => {
  if (!(await checkUpperCode())) {
    alert("입력한 상위코드가 존재하지 않습니다.");
    return;
  }

  const params = {};
  for (var key in state.item) {
    params[key] = state.item[key].value;
  }
  const res = await codeService.update(state.code, params);
  router.push({
    name: "ReadCodeItem",
    params: { code: state.code },
  });
  state.mode = "ReadCodeItem";
};

const clickRemoveBtn = async () => {
  const confirmText =
    "선택한 코드의 하위코드도 모두 삭제됩니다.  \n정말 삭제하시겠습니까??";
  if (confirm(confirmText)) {
    await codeService.deleteAll({ codeList: state.code });
    router.push({
      name: "ReadCodeList",
    });
  } else {
    return;
  }
};

const checkUpperCode = async () => {
  const currentUpperCodeValue = state.item.upperCode.value;
  if (
    currentUpperCodeValue != "" &&
    (await codeService.findOne(currentUpperCodeValue)).status == 500
  ) {
    return false;
  }
  return true;
};

// # life cycle
onMounted(() => {
  if (state.mode != "CreateCodeItem") {
    getItem();
  }
});
</script>

<style lang="scss" scoped></style>
